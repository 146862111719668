/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import {RemixBrowser, useLocation, useMatches} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import {StrictMode, startTransition, useEffect} from 'react';
import {hydrateRoot} from 'react-dom/client';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a3e846d905529b9b08ec539bcd1a4db4@o4506096861839360.ingest.sentry.io/4506096865247232',
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
